/* Common styles for PatientRegister and UserRegister  screen sizes */
.form-container22 {
  width: calc(100% - 230px);
  width: 99%;
  margin: 0 auto;
  border: none;
  font-family: Arial, sans-serif;
  color: var(--labelcolor);
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;



}

.header-patient33 h3 {
  background-color: var(--ProjectColor);
  color:var(--labelcolor);
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  text-align: start;
  justify-content: flex-start;

}

.form-row55 {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
  border-bottom: 1px solid var(--ProjectColor);
  margin-top: 18px;
}

.form_row_66 {
  display: flex;

  width: 350px;
  margin: 3px 0px;
  border-bottom: 1px solid var(--ProjectColor);

}

.form_row_66 select {
  width: 280px;
  outline: none;
  border: none;
  /* position: relative; */
  margin-right: 35px;
  background-color: var(--selectbackgroundcolor);

}



.form_row_66 label {
  font-weight: bold;
  text-align: start;
  margin-right: auto;
  padding-top: 7px;
  width: 120px;
  padding: 5px;
  /* background-color: aquamarine; */
  margin-right: 60px;
  color: var(--labelcolor);

}

.form_row_66 input {
  flex-basis: auto;
  border: none;
  outline: none;
  width: 170px;
  font-size: 13px;
  padding: 5px;
  margin-left: -50px !important;
  /* background-color: antiquewhite; */




}

.custom-file-title {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  padding: auto;
  border-radius: auto;
}

.buttonfooter {
  text-align: center;
  padding: auto;
  display: flex;
  justify-content: center;
  gap: 30px;

}

.buttonfooter button {
  padding: 10px;
  width: 110px;
  border-radius: 25px;
  background-color: var(--ProjectColor);
  border: none;
  margin-top: 10px;

}

.buttonfooter button:hover {
  background-color: var(--ProjectColor);
  color: var(--projectwhite);
  cursor: pointer;
}

::placeholder {
  color: rgb(175, 166, 166);
}


.choose-file-choose {
  color: rgb(33, 32, 32);

  border-radius: 20px;


}

.choose-file-choose {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;


}


.custom-file-title {
  background-color: var(--ProjectColor);
  color:var(--labelcolor);
  padding: 5px 8px;
  border-radius: 85px;
  cursor: pointer;
  transition: background-color 0.3s ease;



}

.custom-file-title:hover {
  background-color: var(--ProjectColorhover);
  color: var(--projectwhite);

}

#genderhead {
  width: 120px;

}

.gender-options-gender {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 1px;




}





.new_choose_1 {
  margin-bottom: 15px;
  position: relative;
  right: 40px;


}

.new_choose_1 {
  margin-bottom: 10px;
  position: relative;
  top: 0px;




}



.new_choose_1 #photo {
  display: none;
}


.custom-file-title {
  padding: 10px 20px !important;
}

#patientidno {
  margin-bottom: 5px;
  padding-top: 15px;
}


.media_screen_1 {
  display: none;
}

.addlocper{
  display: flex;
  
  color: var(--labelcolor);
  padding: 5px;
}
.download-button,
.print-button {
  padding: 5px;
  width: 90px;
  border-radius: 25px;
  background-color: var(--ProjectColor);
  border: none;
  /* margin-top: 10px; */
  color:var(--labelcolor); /* Button text color */
  cursor: pointer;
  /* margin-left: 10px; */
}

.download-button:hover,
.print-button:hover {
  background-color:var(--ProjectColorhover);
  color: var(--projectwhite);
  cursor: pointer;
}

.print_emrgcy{
  color: var(--labelcolor);
  border: none;
  outline: none;
  padding: 5px;
  background-color:transparent;
  cursor: pointer;
}

.back_btn_patreg{
  display: flex;
  align-items: center;
    width: 40px !important;
    height: 40px;

}

.patreg2chse{
  display: flex;
  flex-direction: column;
  gap:3px;
  width: 100px;
  font-size: 12px;
  /* background-color: aqua; */

}

.file-input-444 {
  display: flex;
  gap: 10px;
  align-items: center;
  /* position: relative; */
  margin-left: 7px;


}

.formrow_chse_pic label{
  margin-right: 0px !important;
}

.input-container-99 {
  display: flex;
  align-items: center;
 

}

#consentFile{
  display: none;
}

#fileChooser1,#fileChooser2,
#cameraCapture {
  display: none; /* Hide the file inputs */
}

.choose-file-button-55 {
  display: inline-block;
  font-size: 11px;
  padding: 5px 8px !important;
  background-color: var(--ProjectColor);
  color:var(--labelcolor);
  border: none;
  cursor: pointer;
  border-radius: 20px;
  padding: 8px 4px !important;
  margin-right: 0px !important;
  width: 64px !important;
}



/* Style the button on hover */
.choose-file-button-55:hover {
  background-color:  var(--ProjectColorhover);
  color: var(--projectwhite);
}


/* .consent_align{
  display: flex;
  justify-content: center;
  align-items: center;

} */

.consent_fr_prnt{
  width: 130px !important;
  display: flex;
  align-items: center;

}


.showcamera_takepic {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideopen_showcamera_takepic{
position: fixed;
top: 0;
left: 250px;
width: calc(100% - 250px);
height: 100%;
background: rgba(0, 0, 0, 0.6);
display: flex;
justify-content: center;
align-items: center;
}

.showcamera_1_takepic1 ,.sideopen_showcamera_1_takepic1{
  background-color: #fff;
  width:75%;
  height: 77%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* text-align: center; */
  position: relative;
  /* padding-top: 18px; */
}

.captured-image11{
  padding: 10px;
}

.preview345{
  padding: 10px;
}


.Addnamebtn
{
    margin-left:5px;
    margin-top:5px;
    background-color:var(--ProjectColor);
    border: none;
    color:var(--labelcolor);
    height:20px;
    width: 40px;
    font-size: 18px;
    border-radius: 5px;
}
.Doc_detaile_Tables_place{
  width:100%;
  display:flex;
}
.Doc_names_table{
  width:45%;
  overflow-x: auto;
  margin: 0 auto;
  padding-bottom:10px;
}

.Doc_names_table h4{
text-align: center;
color: var(--labelcolor);
}

.Doc_names_table table{
  width: 100%;
  border-collapse: collapse;
  background-color: var(--projectwhite); /* Set the background color for the table */
}

.Doc_names_table th,
.Doc_names_table td{
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Add a light gray border for table rows */
  text-align: center;
}

.Doc_names_table thead tr{
  border-radius: 10px;
}

.Doc_names_table th{
  background-color: var(--ProjectColor); /* Set the header background color */
  color: var(--projectwhite); /* Set the text color for header cells */
  
  
}

.Table_edit_btn{
background-color: transparent;
border: none;
outline: none;
font-size: 16px !important;
color: var(--labelcolor)  !important;

}


.Theater_Doctor_det_submit{
  padding:8px 18px;
  background-color: var(--ProjectColor);
  border: none;
  border-radius:5px;
  font-size:16px;
  cursor: pointer;
  color: var(--labelcolor);
  justify-content: center;
}
.Theater_Doctor_det_submit_div{
  margin-top:15px;
  text-align: center;
  }

  .pls-testname{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3px;
  }

  .lab-undr{
    border-bottom: none;
  }


@media screen and (max-width:1015px) {
  .form-row55 {
    display: block;
    margin-top: 10px;
    border: 0px !important;
  }

  .media_screen_1 {
    display: block;
  }

  .media_screen {
    display: none !important;
  }

  .form_row_66 {
    width: 300px;
    margin-bottom: 0px !important;
    border-bottom: 1px solid var(--ProjectColor);
  }

  .form_row_66 select {
    width: 200px;
    outline: none;
    border: none;
    /* position: relative;
    right: 22px !important; */
    margin-right: 75px;
    background-color: var(--selectbackgroundcolor);

  }

  .form_row_66 label {
    width: 120px;
  }

  .form_row_66 input,
  .form_row_66 label {

    margin-left: 5px;
    padding: 15px 0px;
  }

  .form_row_66 input {
    flex: 1 -1;
  }

  #titleselect {
    width: 100px;
    position: relative;
    right: 50px;
    background-color: var(--selectbackgroundcolor);
  }

  .new_choose_1 {
    margin-bottom: 15px;
    position: relative;
    right: 55px;
    top: 6px;

  }

  #genderpick {
    position: relative;
    width: 12px;
    text-align: center;

  }

  .gender-options-gender {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 1px;
  }

  #genderhead {
    width: 110px;
  }

  .header-patient33 {
    width: 100%;
  }

  .form-container22 {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-bottom: 0px; */

  }

  /* .form_container_44{
     width: fit-content;
   } */

  .buttonfooter {
    text-align: center;
    padding: auto;
    display: flex;
    justify-content: space-around;
    gap: 5px;
  }

  /* .choose-file-button-55{
    margin-right: 464px;
  } */
  .jhjhd label{
    width: 40% ;
  }

  .jhjhd2 label{
    width:40% ;
  }

}

@media (min-width: 732px){

  .choose-file-button-55{
    /* margin-right: 257px !important; */
  }


}

@media (min-width: 1015px) and (max-width: 1320px) {




  .form-row55 {
    display: block;

    margin-top: 10px;
    border: 0px !important;

  }

  .form_row_66 {
    width: 530px;
    margin-bottom: 0px !important;
    border-bottom: 1px solid var(--ProjectColor);

  }

  .form_row_66 select {
    width: 200px;
    outline: none;
    border: none;
    /* position: relative;
    right: 50px !important; */
    margin-right: 158px;
    background-color: var(--selectbackgroundcolor);
  }

  .form_row_66 label {
    width: 210px;
  }

  .form_row_66 input,
  .form_row_66 label {
    margin-left: 2px;
    padding: 15px 0px;

  }

  .form_row_66 input {
    flex: 1 -1;
  
  }

  #titleselect {
    width: 100px;
    position: relative;
    right: 50px;
    background-color: var(--selectbackgroundcolor);
  }

  .new_choose_1 {
    margin-bottom: 15px;
    position: relative;
    right: 60px;
    top: 6px;
  }



  #genderpick {
    position: relative;
    width: 13px;
    text-align: center;

  }

  .gender-options-gender {
    margin-top: 8px;
    margin-left: 40px;

  }

  #genderhead {
    width: 207px;
  }

  .header-patient33 {
    width: 100%;
  }

  .form-container22 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  .form_container_44 {
    width: fit-content;
  }

  .buttonfooter button {

    width: 120px;

  }
  .emer-55{
    position: relative;
    left:90px;
  }
  
}