.Clinic_det_new{
  padding: 10px 5px 5px 5px;
}
  .new_clinic_form {
    height: 40px;
    margin-top: 20px;
    width: 750px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--ProjectColor);                                     
    display: flex;
  }
  .new_user_items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .new_clinic_form_inp{
    background-color: transparent;
    border: transparent;
    margin-right: -25px;
    outline: none;
    flex: 1;
    margin-top: 5px;
  }
  .new_form_first {
    color: var(--labelcolor);
    justify-content: flex-start;
    display: flex;
    width: 120px;
    font-size: 15px;
    font-weight: bold;
  }
  .new_form_pack {
    width: 50%;
    margin-right: 5px;
    display: flex;
    justify-content: flex-start;
  }
  .new_packlog{
    width: 101%;
    margin-right: 5px;
    display: flex;
    justify-content: flex-start;
  }
 
  .clinic_head{
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 5px;
    color: var(--labelcolor);
    background-color: var(--ProjectColor);
  }
  .new_btn1_1{
    margin-top: 20px;
    padding: 8px 20px;
    border-radius: 30px;
    background-color: var(--ProjectColor);
    border: 1px solid var(--ProjectColor);

  }
  .new_btn1_1:hover{
    background-color:var(--ProjectColorhover);
    color: var(--projectwhite);
    cursor: pointer;
   
  }
  
 
  @media (max-width: 1020px) {
    .new_clinic_form {
      flex-direction: column;
      /* padding: 5px; */
      
      margin-top: 0px;
      height: auto;
      border: none;
      
      width:310px;
    }
    .new_first_1{
        width: 125px !important;
      }
    .new_user_items{
      margin-top: 10px;
      display: flex; 
      flex-direction: column;
      padding: 5px;
      border-bottom: 0px !important;
    }
    .new_form_pack{
      width: 100%;
      height:40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      border-bottom:1px solid var(--ProjectColor);                            
      /* flex-direction: column; */
    }
    .new_packlog{
      width: 100%;
      display: flex;
      justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 17px;
      border-bottom:1px solid var(--ProjectColor);  
    }
    .new_form_first{
      width: 120px;
    }
    .new_clinic_form_inp{
      margin-right: 0px;
  
    }

}
  @media (max-width:400px){
    
    .new_clinic_form{
      width:280px;
  }
 }
