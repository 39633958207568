/* patient profile */
.patient_profile {
  padding: 10px;
  box-sizing: border-box;
}
.p_head {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px;
  color: var(--labelcolor);
  background-color: var(--ProjectColor);
}
.p_data {
  margin-top: 10px;
  height: 300px;
  width: 100%;
  display: flex;
}
.p_p_detials {
  width: 400px;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 1px solid var(--ProjectColor);
}

.jnj6 {
  display: flex;
  flex-wrap: wrap;
}
.hid-prof-til {
  height: 345px;
}
.p_p_detial_1 {
  width: calc(100% - 400px);
  height: 100%;
  padding: 0px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p_p_data {
  width: 100%;
  border: 1px solid var(--ProjectColor);
  height: calc(100% - 100px);
  border-radius: 15px;
}
.p_p_data_1 {
  width: 100%;
  border: 1px solid var(--ProjectColor);
  height: 90px;
  border-radius: 15px;
  padding: 0px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ince-tble-emp-pro {
  height: auto;
}
.p_data_1 {
  margin-top: 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.emp-prof-cntraln {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding-top: 30px;
  margin-top: 60px;
}
.daily-atnd-slct {
  width: auto;
  border: none;
  outline: none;
}
.gap-fr-empl-prof {
  gap: 15px;
  justify-content: center;
}

.flx-wrp-modals {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.img-mrgn-tp {
  margin-top: 20px;
}
.p_p_data_detial {
  width: 300px;
  height: 100%;
  border-radius: 15px;
  border: 1px solid var(--ProjectColor);
}
.p_p_data_detial_1 {
  display: flex;
  justify-content: space-around;
  width: 550px;
  /* padding: 10px 0px; */
  border-radius: 15px;
}
.p_p_data_detial_2 {
  width: 380px;
  height: fit-content;
  /* margin-top: 30px; */
  padding: 15px 10px;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid var(--ProjectColor);
}
.p_profile_img_head {
  width: 100%;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-direction: column; */
  gap: 15px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ProjectColor);
}

.cntr-tmelne {
  display: flex;
  justify-content: center;
}
.p_p_space {
  padding: 10px 10px;
}
.p_profile_img {
  width: 35px;
  height: 35px;
  background-color: #333;
  border-radius: 50%;
  margin-top: 10px;
  overflow: hidden;
}

.img_096 {
  width: 110px;
  height: 110px;
  background-color: #333;
  border-radius: 50%;
  margin-top: 10px;
  overflow: hidden;
}

.p_profile_img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.p_profile_name {
  height: 100%;
  width: 200px;
  color: var(--labelcolor);
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.p_profile_name h3 {
  display: flex;
  width: 200px;
  justify-content: flex-start;
}
.p_profile_name h5 {
  height: 100%;
  width: 200px;
  color: var(--labelcolor);
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.p_profile_body {
  width: 100%;
  padding: 12px 5px 12px 0px;
  height: calc(100% - 50px);
}
.p_profile_body h3,
.p_p_data_items h3,
.p_p_data_items h6 {
  width: fit-content;
  color: var(--labelcolor);
}
.p_p_data_items h3 {
  font-size: 16px;
}
.p_profile_body h3 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.p_p_data_items h3:hover {
  color: var(--projectwhite);
  /* background-color: var(--ProjectColorhover); */
}

.p_profile_body_icon {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-size: 13px;
  color: var(--labelcolor);
}

.css-i4bv87-MuiSvgIcon-root{
  font-size: 1.3rem !important;
}
.icn-emp-pro-cs {
  margin-top: 20px;
}
.ieudh78e6e34 {
  color: var(--labelcolor);
  width: 70%;
  display: flex;
  font-size: 13px;
  flex-wrap: wrap !important;
}

.p_profile_body_icon textarea {
  width: 170px;
  height: 50px;
  font-size: 13px;
  padding: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  box-shadow: 1px 1px 5px 5px rgb(231, 229, 229);
}
.p_profile_items {
  width: 200px;
  margin-left: 15px;
  /* background-color: aquamarine; */
}
.p_profile_data {
  margin: 10px;
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 18px;
}
.p_profile_data h3 {
  font-size: 14px;
  margin-left: 0px;
  margin-top: 5px;
  color: var(--labelcolor);
  width: 100%;
}
.p_profile_data h4 {
  width: 150px;
  font-size: 14px;
  margin-left: 0px;
  margin-top: 5px;
  color: var(--labelcolor);
}

.overview h3 {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid var(--ProjectColor);
}
.p_profile_items_11 {
  display: flex;
  justify-content: space-around;
}
.p_p_data_items {
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  color: var(--labelcolor);
  /* background-color: antiquewhite; */
}

.p_p_data_items h3 span,
.p_p_data_items h6 {
  font-size: 12px;
  color: var(--labelcolor);
}

.alifn-stght h6,
h3 {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.bmb-gap h6 {
  margin-left: 5px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.p_profile_data h6 {
  font-size: 13px;
  padding: 5px 3px;
  width: 135px;
  border-radius: 5px;
  background-color: var(--ProjectColor);
}
.flex_1 {
  height: 60px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px var(--ProjectColor);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 210px;
}
.flex_1:hover {
  color: var(--projectwhite);
  background-color: var(--ProjectColorhover);
}
.cente_1 {
  background-color: hsl(33, 70%, 82%);
  display: grid;
  place-items: center;
}
.cente_1:hover {
  background-color: var(--ProjectColorhover);
  color: var(--projectwhite);
}

/* time line */
.dot_1 .dottt {
  background-color: var(--ProjectColor) !important;
}
.p_timeline_img {
  width: 65px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--ProjectColor);
  display: grid;
  place-items: center;
}
.p_p_data_items_left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p_p_data_items_left .p_p_data_items {
  margin-top: 30px;
  background-color: var(--ProjectColor);
}
.p_p_data_items_left .p_p_data_items :hover {
  /* color: var(--projectwhite) ; */
  background-color: var(--ProjectColorhover);
}

.p_p_data_detials_2_img {
  height: 150px;
  width: 150px;
  background-color: rgb(214, 212, 212);
}
.p_p_data_detials_2_img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.p_p_data_detials_2_images {
  display: flex;
  justify-content: space-around;
  row-gap: 20px;
  gap: 30px !important;
}
.p_p_data_detials_2_view {
  margin-top: 15px;
  color: var(--labelcolor);
  display: grid;
  place-items: center;
}

.vie-al-pic {
  display: flex;
  border: none;
  outline: none;

  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3px;
  background-color: transparent;
  color: var(--labelcolor);
  font-size: 15px;
  cursor: pointer;
  /* margin: 10px; */
}

.vie-al-pic:hover {
  color: var(--ProjectColor);
}

.viw-all-allpic {
  width: 600px;
  display: flex;
  overflow: auto;
  overflow-y: auto;
  /* flex-direction: row; */
  /* column-gap: 30px; */
  /* flex-wrap: wrap; */
  /* margin: 20px; */
}

.viw-all-allpic {
  width: 600px;
  display: flex;
  overflow: auto;
  overflow-y: auto;
}

.viw-all-allpic::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 5px;
}

.viw-all-allpic::-webkit-scrollbar-thumb {
  background-color: var(--ProjectColor); /* Color of the thumb */
  border-radius: 5px; /* Border radius of the thumb */
}

.viw-all-allpic::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

.images_show::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 5px;
}

.images_show::-webkit-scrollbar-thumb {
  background-color: var(--ProjectColor); /* Color of the thumb */
  border-radius: 5px; /* Border radius of the thumb */
}

.images_show::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

.image_display32w {
  display: flex;
  flex-wrap: wrap;

  /* row-gap: 5px; */
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 10px;
  border: 1px solid var(--ProjectColor);
  border-radius: 5px;
  padding: 5px;

  margin-right: 5px;
}

.images_show {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  padding-top: 40px;
  padding: 10px;
  margin: 0px 30px;
  height: 60%;
  width: 90%;
}


.shdiwjh34 {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 102px;
  margin: 20px;
  font-size: 15px;
  color: var(--labelcolor);
}
.shdiwjh34:focus {
  background-color: var(
    --ProjectColor
  ); /* Background color for the focused button */
}

.shdiwjh34:not(:focus) {
  background-color: var(
    --projectwhite
  ); /* Reset background color for other buttons */
}

.wasx {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 200px;
  /* background-color: ; */
}

.images_show_1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.jqwgdyw56 {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.edferki4 img {
  width: 150px;
  height: 150px;
}

.sideopen_showcamera_profile {
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.showcamera_profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.newwProfiles {
  background-color: #fff;
  width: 80%;
  height: 75%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow: auto;
  position: relative;
}
.newwProfiles .appointment {
  width: 99%;
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  padding: 0px;
}

.closeicon-cs {
  border: none;
  outline: none;
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  margin: 10px;
  margin-bottom: 20px !important;
}
.closeicon-cs:hover {
  background-color: var(--ProjectColorhover);
}

.text-hmeicn {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--ProjectColor);
  border-radius: 10px;
  padding: 10px;
  height: 60px;
  color: var(--labelcolor);
  width: 80%;
}

.flex_vital {
  width: 200px;
  background-color: var(--ProjectColor);
}

.flex_vital:hover {
  /* background-color: var(--ProjectColorhover); */
  color: var(--projectwhite);
}
.pdf_img_show {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.clse_pdf_img {
  color: var(--labelcolor);
  font-size: 30px;

  position: relative;

  border-radius: 20px;
}

@media (max-width: 1080px) {
  .p_head {
    width: 98%;
  }
  .p_data,
  .p_data_1 {
    flex-direction: column;
  }
  .p_data {
    height: auto;
  }
  .p_p_detials,
  .p_p_detial_1,
  .p_p_data_detial,
  .p_p_data_detial_1,
  .p_p_data_detial_2 {
    width: 95%;
    margin-top: 25px;
  }
  .p_p_data_1 {
    margin-top: 10px;
  }
  .patient_profile,
  .p_data,
  .p_data_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .p_p_space {
    display: flex;
    justify-content: center;
  }
  .p_p_data_detials_2_images {
    gap: 30px;
  }
  /* .viw-all-allpic {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 10px;
  } */
  .p_profile_img_head {
    width: 100%;
    /* height: 70px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* flex-direction: column; */
    gap: 100px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--ProjectColor);
  }
  .text-hmeicn {
    width: 80%;
  }

  .p_profile_body_icon {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    color: var(--labelcolor);
  }

  .images_show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    padding: 0px 0px;
    height: 100%;
    width: 85%;
    overflow-y: auto;
    overflow-x: auto;
  }
  .emp-prof-cntraln{
    margin-top: 0px;
    padding-top: 0px;
  }
}
@media (max-width: 800px) {
  .p_profile_items_11 {
    display: block;
  }
  .p_profile_items {
    width: 90%;
    display: grid;
    place-items: center;
  }
  .p_p_data_detials_2_img {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: fill;
    flex-direction: column;
  }

  .p_p_data_detial_1 {
    display: block;
  }
  .p_p_data_items_left .p_p_data_items {
    margin-top: 15px;
  }
  .p_p_data_1 {
    padding: 0px 10px;
  }
  .p_p_data_detials_2_images {
    gap: 5px;
  }

  .viw-all-allpic {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 10px;
  }

  .pdf_img_show {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 81%;
    width: 100%;
    margin-top: 63px;
  }

  .selt-dctr-nse select {
    width: 125px !important;
  }
  .selt-dctr-nse label {
    font-size: 13px;
    width: 100px;
  }

  .p_p_data_detial_1 {
    display: block;
  }
  .p_p_data_1 {
    height: auto;
    box-sizing: border-box;
    padding: 10px 0px;
    flex-direction: column;
    gap: 20px;
  }
  .p_p_data_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 250px !important;
    gap: 5px;
    /* border-bottom: 1px solid var(--ProjectColor); */
  }
  .flex_1 {
    flex-direction: row;
  }
  /* .tiger-123{
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;
    } */

  .text-hmeicn {
    width: 80%;
  }

  .p_profile_img_head {
    width: 100%;
    /* height: 70px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--ProjectColor);
  }
  .p_profile_name {
    height: 50%;
    width: 200px;
    color: var(--labelcolor);
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .p_profile_name h3 {
    display: flex;
    width: 200px;
    justify-content: center;
  }

  .ieudh78e6e34 {
    color: var(--labelcolor);
    width: 70%;
    display: flex;
    font-size: 13px;
    flex-wrap: wrap !important;
  }

  .edferki4 img {
    width: 100px;
    height: 100px;
  }

  .images_show {
    display: flex;

    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 10px;
    padding: 0px 0px;
    height: 100%;
    width: 80%;
  }

  .jqwgdyw56 {
    flex-direction: column;
    row-gap: 10px;
  }

  .edferki4 img {
    width: 150px;
    height: 150px;
  }

  .image_display32w {
    /* justify-content: center;
    width: 300px; */
    border: none;
  }
  /* .wasx {
    height: 50px !important;
  } */
}

@media (min-width: 950px) and (max-width: 1290px) {
  .p_p_data_detial_1 {
    display: block;
  }
  .p_p_data_items_left .p_p_data_items {
    margin-top: 15px;
  }
  .p_p_data_1 {
    padding: 0px 10px;
  }
  .p_p_data_detials_2_images {
    gap: 10px;
  }

  .images_show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: auto;
    height: 100%;
  }

  .wasx {
    height: 50px;
  }

  .viw-all-allpic {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: auto;
    overflow-y: auto;
    overflow-x: auto;
    /* flex-direction: row; */
    /* column-gap: 30px; */
    /* flex-wrap: wrap; */
    /* margin: 20px; */
  }
}
