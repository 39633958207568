
body{
  margin: 0px;
  padding: 0px !important;
 }
.register-container{
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color:var(--ProjectColor);
}
.togglebutton_container_login{
  width: 100px ;
  height: 30px ;
  
}
.register {
  width: 60%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px 5px;
  background-color: #fff;

}
.col-1{
  width: 60%;
  height: 100%;
  padding: 5px 0px;
  box-sizing: border-box;
}
.col-2{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;

}
.col-1 img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.flex-col{
  width: 100%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.login-logo{
  width:220px ;
  height: 75px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.login-logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.input-group{

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-bottom: 1px solid  var(--ProjectColor);

}
.input-icon{
  color: #000;
}
.input-group input{
  width: 160px;
  height: 40px;
  border: 0px;
  outline: 0px;
  margin-left: 10px;
  background-color: transparent;
  padding-left: 10px;
}
.login-btn{
  padding: 10px;
   width: 110px;
   border-radius: 25px;
   background-color:  var(--ProjectColor);
   border: none;
   margin-top: 10px;
}
.login-btn:hover{
  background-color: var(--ProjectColorhover);
  cursor: pointer;
}

.loctn-big{
  font-size:19px;
  /* margin-right: 8px; */
}
.input-group select{
  border: none;
  outline: none;
  width: auto;
  width: 170px;
  margin-left: 15px;

  padding-left: 10px;
  height: 32px;
  margin-bottom: 5px;
  background-color: var(--selectbackgroundcolor);
}

.input-group select option {
  overflow-y: auto !important; 
  max-height: 200px !important; 
  
}



.passd321{
  width: 160px !important;
}

.clr-clck-her4 {
  display: flex;
font-size: 13px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  font-style: italic;
}

.clr-clck-her4 h5{
  width: 100%;

}

.flx_dued3{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap:5px;
  cursor: pointer;
}

.clr-clck-her4 span{
  color: blue;
  border-bottom: 1px solid blue;
}

.clr-clck-her4 h4{
  color:   var(--ProjectColor);
  font-size: 14px;

}
.login-btn:hover{
  color: white;
}


.input-group {
  position: relative;
}

.input-group input[type='password'] {
  padding-right: 0px; /* Adjust this value according to your icon size */
}

.input-group .toggle-password {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  padding: 5px 6px 0px 5px;
}



@media (max-width:1000px){
  .register{
    flex-direction: column;
    height: 100%;
    width: 300px;
    gap: 5px;
    padding-bottom: 5px;
  }
  .col-1,.col-2{
    width: 90%;
    height: fit-content;
  }
  .col-1{
    width: 300px;
    display: grid;
    place-items: center;
  }
  .col-1 img{
    width: 290px;
    height: 260px;
    border-radius: 20px;
  }

  .login-logo{
    width: 205px;
    height: 55px;
    border-radius: 10px;
    display: grid;
    place-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  .flex-col{
    gap: 11px;
  }
  .login-logo img{
    width: 80%;
    height: 83%;
  }
  .input-group input{
    height: 25px;
  }
  .register-container{
    padding: 15px 0px;
  }

  .login-btn{
    margin-top: -5px;
  }
  .loctn-big{
    font-size: 19px;
    padding-right: 10px;
  }

  .input-group select{
     border: none;
    outline: none;
    width: auto;
    width: 168px;
    margin-left: 8px;
    padding-left: 5px;
    height: 30px;

  
  }

  .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type),.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type){

    padding: 0px 10px !important;
  }
  .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type){
    padding: 0px 45px !important;
  }
}

    