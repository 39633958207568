/* Master.css */
body {
    overflow-x: hidden;
}

.MasterPurchase1,
.MasterPurchase2 {

    width: 100%;
    display: flex;
    flex-direction: column;



}

.MasterPurchase2 {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;


}

.manufacturer-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #fff;
    gap: 20px;
}

.manufacturer-content {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

}

.manufacturer-head-master {
    height: 40px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: var(--ProjectColor);
    /* margin: 5px; */
}
.IP_grid_1 {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.IP_norecords{
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    place-items: center;
    color: var(--labelcolor);
  }
.Ip_data_grid {
    border: none;
    width: 100%;
    overflow-x: auto;

}

.css-wop1k0-MuiDataGrid-footerContainer {
    min-height: 0px !important;
}




.IP_grid{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.Supplier_inputss_forms{
    width: 325px;
    /* height: 50px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-bottom:  1px solid var(--ProjectColor);
}
.Supplier_inputss_forms label{
    color: var(--labelcolor);
}
.Supplier_inputss_forms input{
    border: 0px;
    outline: 0px;
    background-color: transparent;
}
.code-code-invent {
    width: 80%;
    height: 20px;
    outline: 0px;


}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-clear-button,
input[type="number"]::-webkit-search-cancel-button {
  display: none !important;
}




.label-Purchase {
    color: var(--labelcolor);
    font-size: 14px;
    /* Set your desired font size */
    font-weight: bold;
    display: flex;
}


.pur-flex-master {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    justify-content: flex-start;
}



.master-purchase-btn {
    text-align: center;
    padding: 10px;
    justify-content: center;
    display: flex;
    gap: 30px;
}

.submit-master {
    padding: 8px 20px;
    border-radius: 30px;
    background-color: var(--ProjectColor);
    border: 1px solid var(--ProjectColor);
}

.submit-master:hover {
    background-color:  var(--ProjectColorhover);
    color: var(--projectwhite);
    cursor: pointer;
}

.purchase-row-number {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.purchase-row-number1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.row-buttons {
    display: flex;
    flex-direction: row;
    padding-left: 40%;
    height: 30px;
}

.Manu_Manu {


    height: 25px;
    width: 35%;
}

.btn-manu {
    width: 80px;
    height: 30px;
    background-color: var(--ProjectColor);
    border: none;
    border-radius: 5px;
}

.btn-manu:hover {
    background-color: var(--ProjectColorhover);
    color: var(--projectwhite);
}

.continer {

    width: 100%;
    display: flex;
    gap: 10px;
    box-sizing: border-box;
}

.con1 {
    width: 50%;

}

.togglebutton-container-master {
    width: 200px !important;
    height: 30px !important;
    font-size: 10px !important;
}





/* raise popup */

.Raise_indent_popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}
.Raise_indent_popup_con{
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 350px;
    width: 100%;
    overflow: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Raise_indent_form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px ;
    box-sizing: border-box;
    gap: 20px;
}
.Raise_indent_div{
    width: 300px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}
.Raise_indent_div label{
    width: 100px;
    color: var(--labelcolor);
    display: flex;
    justify-content: space-between;
}
.Raise_indent_div input{
    outline: 0px;
    height: 25px;
}
.Raise_indent_btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Raise_indent_btn button{
    width: 100px;
    background-color: var(--ProjectColor);
    padding: 8px 10px;
    outline: 0px;
    border: 0px;
    border-radius: 10px;
}


.inputColumn{
    width: 150px;
    height: 20px;
}


.css-1hv55gt-MuiTypography-root {

    width: 80% !important;
}

/* rateee */

.RateCard_table{
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
}
.RateCard_table_1 {
    width: 100%;
    min-width: max-content;
    padding: 10px 0px;
    display: flex;
    /* overflow: auto; */
    align-items: center;
    justify-content: center;
}
.RateCard_table_1 table {
    width: 95%;
    border-collapse: collapse;
}
.RateCard_table_1 table thead{
    background-color: var(--ProjectColor);
}
  
  
.RateCard_table_1 td{
    padding: 10px 0px;
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid #ccc;
}
  
  /* Table Header Styles */
.RateCard_table_1 th {
    padding: 5px ;
    
    color:var(--labelcolor);
    font-size: 15px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-right: 2px solid white;
    border-radius: 10px;
    /* border-radius: 5px; */
    
}
  /* Table Row Styles */
.RateCard_table_1 tbody tr:nth-child(even) {
    background-color: rgba(224, 221, 223, 0.384);
}
  
  /* Hover Styles */
.RateCard_table_1 tbody tr:hover {
    background-color: #5b595917;
}
  
  /* Total, Paid Amount, and Balance Due Styles */
 
.ratecarededitbutton{
    color: var(--labelcolor);
    height: 20px;
    border: 0px;
    outline: 0px;
    background-color: transparent;
}
:is(.css-axafay-MuiDataGrid-virtualScroller,.css-u3xcjc-MuiDataGrid-root,.RateCard_table)::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
    height: 8px  !important;
  }
  
  /* Customize the scrollbar thumb (the draggable part) */
:is(.css-axafay-MuiDataGrid-virtualScroller,.css-u3xcjc-MuiDataGrid-root,.RateCard_table)::-webkit-scrollbar-thumb {
    background: var(--ProjectColor) !important; /* Set the color of the thumb */
    border-radius: 6px !important; /* Round the edges of the thumb */
  }
  
  /* Customize the scrollbar track on hover */
:is(.css-axafay-MuiDataGrid-virtualScroller,.css-u3xcjc-MuiDataGrid-root,.RateCard_table)::-webkit-scrollbar-track {
    background: rgb(197, 196, 196) !important; /* Set the color of the track on hover */
  }
  
  /* Customize the scrollbar thumb on hover */
:is(.css-axafay-MuiDataGrid-virtualScroller,.css-u3xcjc-MuiDataGrid-root,.RateCard_table)::-webkit-scrollbar-thumb:hover {

    background: var(--ProjectColor) !important; /* Set the color of the thumb on hover */
}
@media screen and (max-width: 768px) {

    body,
    html {
        overflow-x: hidden;
    }

    .togglebutton-container-master {
        width: 90px !important;
        height: 30px !important;
        font-size: 7px !important;
        align-items: center !important;
    }

    .label-Purchase {
        color: var(--labelcolor);
        font-size: 14px;
        /* Set your desired font size */
        font-weight: bold;
        display: flex;
    }


    .pur-flex-master {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        justify-content: center;
    }

    .code-code-invent {
        width: 80%;
        height: 20px;

    }

    .purchase-row-number1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

    }

    .master-inventory-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .MasterPurchase1 {

        width: 90%;

    }

    .purchase-row-number {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

    }

    .continer {

        width: 100%;
        display: flex;
        gap: 10px;
        box-sizing: border-box;
        flex-direction: column;
    }

    .con1 {
        width: 100%;

    }
.RateCard_table_1 td,
.RateCard_table_1 th {
  font-size: 10px;
}

    
}
