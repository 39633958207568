
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.appointment_new2 {

  background-color: burlywood;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  }


  /* Additional styling for content within .appointment_new2 */
.appointment_new2 h1, .appointment_new2 h2, .appointment_new2 h3, .appointment_new2 p {
  margin: 1em;
}

/* Optional: Styling for a scroll bar within .appointment_new2 */
.appointment_new2::-webkit-scrollbar {
  width: 3px;
}

.appointment_new2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.appointment_new2::-webkit-scrollbar-thumb {
  background: #888;
}

.appointment_new2::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  
  .h_head_88 {
    text-align: center;
    background-color: #433c3c;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  
  .h_head_88 h2 {
    display: flex;
    /* width: 50%; */
    justify-content: center;
  
    font-size: 25x;
    font-family: 'Josefin Sans';
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .h_head_88 h2 p {
    margin: 0;
    padding: 0;
    background-color: var(--ProjectColor);
    padding: 5px 10px;
  }

  .kidiis8_img{
    width: 220px;
    height: 65px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    /* margin-top: 25px; */
    margin-left: 5px;
  }
  .edciwe7{
    /* width: 100%;
    height: 100%; */
    display: flex;
    justify-content: center;

    align-items: center;

    /* row-gap: 15px; */
    background-color: burlywood;
    margin: 13px 0px 15px 0px;
  

  }


  .iiopk8{
 width: 70%;
 height: 402px;
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0 0px 10px rgba(0, 0, 1, 1);
    background-color: #ffe1f9;
    flex-direction: column;


  }

  .form-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    height: 300px;
    margin: 10px;

    background-color: transparent !important;
  }
  
  .form-step label {
    display: block;
    margin-bottom: 10px;
 
  }
  
  .form-step input{

    width: 200px;
    padding: 9px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .form-step textarea {
    width: 250px;
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white !important;
  }

  .ewuii78{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .ewuii78 button {
    margin: 5px;
    margin-top: 10px;
    padding: 8px 20px;
    border: none;
    border-radius: 20px;
    background-color: #3d3d3d;
    color: white;
    cursor: pointer;
  }
  
  .ewuii78 button:hover {
    background-color: #262626;
  }

  .jjk845{
    padding: 0px;
    margin: 0px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .oisd67{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    row-gap: 1px !important;
    gap: 10px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 5);
    background-color:#be323294;
    padding: 13px 13px 9px 13px;  
      border-radius: 5px;

  }

  .oisd67 label {
    width: 204px;
    font-weight: 600;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    background-color: #d1d1d1 !important;
    border-radius: 5px;
    font-size: 12px;
  }
  .oisd67 input {
outline: none;
border: 1px solid #d1d1d1;
border-radius: 5px;
height: 12.5px;

  }

  .oisd67 select{
    width: 220px;
    height: 32px;

    margin-bottom: 10px;
    outline: none;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
   background-color: #efefef;
  }

.njkloi9 label{
  width: 250px;
}
.wsdq37887{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
   

}
  .wsdq37887 label{
    width: 100px !important;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 5px;
    padding: 5px;
    background-color: white !important;

  }

  .wsdq37887 input{
    margin: 0px;
    width: 18px;
    height: 18px;
    /* transition: ; */
  }

  .udsuyu7yw7{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: 3px;
  }

  .ewcflkwekl label{
    width: 220px;
    background-color: white;
  }

  .s88uu textarea{
width: 300px;
background-color:white;
  }

  .dwuiud7 label{
    width: 375px;
    justify-content: center;
    gap: 10px;
  }

  .ffffffcd 
  {
   display: flex;
   flex-direction: column;
  }

  .ffffffcd label{
    width: 296px;
    justify-content: center;
    gap: 10px;
  }

  .ecdc label{
width: 300px !important;
justify-content: center;
gap: 10px;
  }

  .defhuyewdtqw_ij{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 105px; */
    flex-wrap: wrap;
  }


  .sigCanvas33{
    width: 310px;
    height: 140px;
    border: 1px solid #d1d1d1;
    margin-bottom: 5px;
    background-color: white;
  }

  .dwee221{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    /* background-color: white; */
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate-image {
    animation: rotate 20s linear infinite;
  }
  
  
  .ewoooon {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 5px; */
  }
  .ewoooon button{
    width:50px;
    padding: 2px 5px;
    background-color:#d1d1d1 ;
    border-radius: 5px;
    border: none;
    /* cursor: pointer; */
    font-weight: bold;
    /* font-size: ; */
  }

  .ewoooon button:hover{
    color: white;
  }
  
  .WEKJFUYE{
    display: flex;
    width: auto;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    margin: 2PX 0PX;
    /* background-color:#6d6d6d; */
    padding: 5px;
  }
  .WEKJFUYE h3{
    display: flex;
    /* width: 50%; */
    justify-content: center;
  
    font-size: 25px;
    font-family: 'Josefin Sans';
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .WEKJFUYE h3 p {
    margin: 0;
    padding: 0;
    background-color: var(--ProjectColor);
    padding: 5px 10px;
    font-size:17px;
  }

  .kjedi8 {

    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #454545;
    padding: 8px;
    font-size: 15px;
    border-radius: 4px;
    border: 3px solid transparent; 
    animation: borderMove 6s infinite linear, shadowBlink 3s infinite alternate; 
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);

  }
  
  @keyframes borderMove {
    0% {
      border-color: #ff3305; 
    }
    25% {
      border-color: #00ff2f;
    }
    50% {
      border-color: #2f00ff;
    }
    75% {
      border-color: #fc002e; 
    }
    100% {
      border-color: #ff2f00;
    }
  }
  
  @keyframes shadowBlink {
    0%, 100% {
      box-shadow: 0px 0px 15px rgb(255, 136, 0); 
    }
    50% {
      box-shadow: 0px 0px 15px rgba(255, 136, 0, 0.644); 
    }
  }
  





.kjedi8 label{
    width: 105px;
    color: #ffc120;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
}

.kjedi8 input{
    width: 18px;
    height: 18px;
}
  .lolp0{
    width: 190px !important;
  }

.jij7789{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}


.jjk845 h3 p{
background-color: #c24440;
color:#f6f6f6;
}


/* 
.background-image img {
  
  height: 320px;
  display: flex;  justify-content: center;
  width: 320px;
position: relative;
  align-items: center;
  opacity: 0.2;

} */

/* Inside YourComponent.css */

.background-image {
  position: relative;
}



.background-image img {
  position: absolute;
  top: 5%;
  left: 51%;
  width: 220px;
  height: 220px;
  /* bottom: 400px; */
  z-index: 0;
  opacity: 0.1;
  filter: sepia(1) hue-rotate(60deg) brightness(1.5); 

}



.empty-input,.error {
  border: 1px solid yellow !important;
  animation: blinkBorder 1s linear infinite;
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.89); /* Yellow shadow */
}

@keyframes blinkBorder {
  0% {
    border-color: yellow;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: yellow;
  }
}








.djhweyd67u79{
  width: 100%;
  display: flex;
  justify-content: center;
}
.djhweyd67u79 p{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin: 0px;
  width: 50%;
  font-family: 'Times New Roman', Times, serif;
}







  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  /* @media (max-width: 706px)  {

  
    .background-image img{
      left: 22%;
      width: 240px;
      top: 2%;
      height: 240px;
    }
    
  } */


  .newwPopupforreason_854{
    width: 45%;
    height: 55%;
    justify-content: center;
  }


  .custom-toast-message {
    font-family: 'Pacifico', cursive;
    font-size: 14px;
    color: white; /* Dark color for elegance */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    text-align: center;
  }
  

  /* RegisterFormNew.css */

.custom-toast-message {
  font-weight: bold;
  text-align: center;
  font-size: 14px !important;
  color: black;
  background-color: bisque;
  padding: 20px;
}

@media (min-width: 600px) {
  .custom-toast-message {
    font-size: 1.5rem;
  }
}

.toast-container-over-header_o {
  z-index: 9999;
  display: flex;
  position: relative;
  right: 85px;
  align-items: center;
  justify-content: center;
  width: 523px !important;
  height: 330px !important;
  background-color: #973322;
}

.teddy-bear {
  position: relative;
  width: 85px; /* Adjust the size as needed */
  /* Adjust the size as needed */
  margin: 0 auto; /* Center the container */
  animation: shakeTeddy 1s infinite;
}


.teddy-bear img{
  width: 70px;
  object-fit: contain

}

@keyframes shakeTeddy {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-2px, 2px); /* Slightly move left down */
  }
  50% {
    transform: translate(2px, -2px); /* Slightly move right up */
  }
  75% {
    transform: translate(-2px, -2px); /* Slightly move left up */
  }
  100% {
    transform: translate(2px, 2px); /* Slightly move right down */
  }
}



.thank-you-page_09{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  padding: 10px;
  background-color: #080839;
  height: 100vh;

}

.kuedh676565{
width: 100% !important;
  height: 80px !important;
  margin-top: 10px;
}

.tdy_uo9{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 50px;
}

.tdy_uo9 h1,.tdy_uo9 p{
  color: white;
  font-family: 'Pacifico', cursive;
}

/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');

.tdy_uo9 h1 {
  font-family: 'Tangerine', cursive;
  font-size: 3em;
  color: #db7093; /* Example color */
}

/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');


.tdy_uo9 p {
  font-family: 'Playball', cursive;
  font-size: 1.1em;
  color: lavenderblush; /* Example color */
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}



  @media (max-width: 715px)  {
    .oisd67{
        flex-direction: column;
        row-gap: 3px;
        justify-content: center;
        align-items: center;
    }

    .oisd67 label{
        width: 200px;
        justify-content: center;
        gap: 5px;
       
    }

    .iiopk8{
      height: 345px;
      justify-content: space-between;
      padding: 0px 0px 30px 0px;
    }

    .kidiis8_img{
      width: 190px;
      height: 60px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-left: 5px;
        align-items: center;
        margin-top: 0px;

    }

    .form-step textarea{
        width: 204px;
       
    }

    .wsdq37887 label{
        justify-content: flex-start;
        width: 105px !important;
        margin-bottom: 0px;
    }

    .wsdq37887{
        gap: 5px;
        row-gap: 5px
        /* width: 100px; */
    }

    .oisd67 select{
        width: 214px;
    }

    .h_head_88 h2 ,.jjk845 h3 {
        font-size: 17px;
    }

    .jjk845 h3 p{
        font-size: 14px;
    }

    .ecdc label{
        justify-content: center;
        align-items: center;
    }
      .sigCanvas33{
    width: 210px;
    height: 140px;
    border: 1px solid #d1d1d1;
    margin-bottom: 5px
  }

  .jjk845{
    padding: 0px;
    margin: 0px;
    height: 20px;
  }

  .iiopk8{
    width: 90%;
  }

  .form-step textarea{
    width: 200px !important;
  }

  .udsuyu7yw7 label
  {
    width: auto !important;
  }

  .eddcdcd textarea{
    width: 230px !important;
  }

  .dwuiud7 label{
    width: auto;
  }

  .WEKJFUYE{
    row-gap: 10px;
  }

  .kjedi8{
    gap: 5px;
    padding: 7px;
  }

  .h_head_88{
    padding: 0px;
  }

  .h_head_88 h2 p{
    font-size: 16px;
  }

  .form-step.form-step{
    height: 200px;
  }
  .background-image img{
    left: 8%;
    width: 240px;
    top: -19%;
    height: 240px;
  }

 
  }

  @media (max-width: 600px)  {
    .newwPopupforreason_854{
      width: 90%;
      height: 55%;
      justify-content: center;
    }

    .toast-container-over-header_o{
      width: 100% !important;
      right: 0px;
      height: 100% !important;
    }

    .kuedh676565{
      width: 100% !important;
      height: 70px !important;
    }
  }

  @media (max-width: 450px)  {
    .lolp0{
        width: 145px !important;
      }

      .kjedi8 label{
        width: 145px !important;
        font-size: 13px;
       
      }
      .toast-container-over-header_o{
        width: 92% !important;
        right: 0px;
        left: 12px;
        height: 100% !important
      }
  }

  






