@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');


body{
  background: #f4f3f2;  
  background: -webkit-linear-gradient#F59040;  
  background: linear-gradient#F59040; 
  
}
:root{
  --ProjectColor:#eba308;
  --ProjectColorhover:#ffb109;
  --labelcolor:#000;
  --projectwhite:white;
  --selectbackgroundcolor: #e7e4df;
  --fontsize: 12px;
  --opacityColor:#010118;
}




.toast-container-over-header{
  /* background-color: aqua;
  width: 200px;
  height: 50px; */
  position: absolute;
  top: 20px;
  /* bottom: 0; */
  right: 10px;
  z-index:9999;
}


/* @page {
  size: 8.5in 11in;
  margin: 0;
}

body {
  font-size: 42px;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.headerrrr {
  height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  background: transparent;
  z-index: 1000;
}

.contentdd {
  margin-top: 160px; 
  padding: 20px;
}

.pageee {
  page-break-after: always;
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: calc(100vh - 150px - 75px);
}

.page-content {
  text-align: center;
}

.footerrr {
  height: 75px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 1000;
}

.footerrr > div {
  text-align: center;
  width: 100%;
} */


