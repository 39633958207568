










body{
    padding: 10px;
}
.web_App{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.web_head {
    width: 90%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 5px;
    color: var(--labelcolor);
    background-color: var(--ProjectColor);
   
  }
.camera-container{
    width: 100%;
    height: fit-content;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.camera-container video{
    width: 100%;
}
.RotateButton_canva{
    position: relative;
    width: 350px;
    height: 265px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.web_cam__1 button{
   
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0px;
    border: 1px solid #fff;
    color: var(--labelcolor);
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.RotateButton_canva{
    position: relative;
}
.RotateButton_canva button{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0px;
    border: 1px solid var(--ProjectColor);
    color: var(--labelcolor);
    border-radius: 50%;
    height: 40px;
     width:40px;
}

.btncon_add {
    padding: 5px 20px;
    border-radius: 30px;
    background-color: var(--ProjectColor);
    border: 1px solid var(--ProjectColor);
    cursor: pointer;
  }
  
  .btncon_add:hover {
    background-color:var(--ProjectColorhover);
    color: var(--projectwhite);
  }

.web_btn{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}
.web_camera{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--ProjectColor);
}
.web_sidebar{
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0px;
    background-color: var(--ProjectColor);
    width: 60px;
    height: 300px;
    color: #fff;
    overflow: auto;
    margin-left: 20px;
}
.web_cam{
    width: 50%;
}
.web_cam__1{
    width: 100%;
    position: absolute;
    top: 5px;
    padding-left: 2px;
    background-color: transparent;
}
.w_side_icon{
    cursor: pointer;
    font-size:30px !important;
}
.web_camera_svg{
    width: 450px !important;
    height: 300px !important;
    position: relative;
    width: 100%;
    height: 100%;
}
.web_camera_svg img{
    width: 85%;
    height: 100%;
}
.web_camera_svg svg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100% ;
    height: 100%;
}
.web_select{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    width: 250px;
}
.web_select_1{
    display: flex;
    justify-content: space-between;
    width: 230px;
    padding: 10px;
    border-bottom:  1px solid var(--ProjectColor);
}
.select_middle{
    align-items: center;
}
.icon_rotate{
    rotate: 90deg;
    cursor: pointer;
   
}

.web_select_1 label{
    width: 100px !important;
    color: var(--labelcolor);
}
.web_select_1 select{
    width: 150px;
    outline: none;
    border: none;
}
.width_side{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
}

.color_options{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    padding-right: 5px;
    width: 100%;
}
.thick_options{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
    color: var(--labelcolor);
    padding-right: 5px;
}
.color_option{

   font-size: 20px !important;
}
.clr_opt{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.clr_opt span{
    flex: 1;
}
.font_size_1{
    width: 30px;
}

@media (min-width:501px) and (max-width:828px){
    .camera-container video{
        height: 225px;
    width: 300px;
    }
    .web_cam__1{
        width: 80%;
        /* height: 270px; */
    }
    .RotateButton_canva{
        height: 225px;
    }
    .web_camera{
        position: relative;
        align-items: flex-start;
        border: 0px;
        width: 350px !important;
        height: 350px !important;
        overflow: hidden;


    }
    
   
    .web_camera_svg,.web_camera_svg svg{
        width: 100% !important;
        height: 100% !important;
    }
    .web_camera_svg{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .web_camera_svg svg{
        
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .web_sidebar{
        
        width: 40px;
        overflow: scroll;
        height: 100%;
        gap: 10px;
    }
    .w_side_icon{
        font-size: 25px !important;
    }
    .color_option{
        font-size: 18px !important;
    }
    .font_size_1{
        width: 20px;
    }
    .web_App{
        width: 100%;
    }
}

@media (max-width:830px){

    .camera-container video{
    height: 225px;
        width: 300px;
    }
    .web_cam__1,.web_cam{
        height: 250px;
        width: 300px;
    }
    .RotateButton_canva{
        height: 225px;
        width: 300px;
    }
    .web_camera{
        position: relative;
        align-items: flex-start;
        border: 0px;
        width: 200px !important;
        height: 200px !important;
        overflow: hidden;


    }
   
    .web_camera_svg,.web_camera_svg svg{
        width: 100% !important;
        height: 100% !important;
    }
    .web_camera_svg{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .web_camera_svg svg{
        
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .web_sidebar{
        
        width: 40px;
        overflow: scroll;
        height: 100%;
        gap: 10px;
    }
    .w_side_icon{
        font-size: 20px !important;
    }
    .color_option{
        font-size: 15px !important;
    }
    .font_size_1{
        width: 20px;
    }.web_App{
        width: 100%;
    }

    .web_head {
        width: 85%;
    }

    .web_btn{
        margin-top: 20px;
        display: flex;
        gap: 10px;
    }
   
    
   
}

@media (max-width:500px){
    .camera-container video{
        height: 150px;
        width: 200px;
        }
        .web_cam__1,.web_cam{
            height: 150px;
            width: 200px;
        }
        .RotateButton_canva{
            height: 150px;
            width: 200px;
        }
        .web_cam__1 button{
            width: 30px;
            height: 30px;
            font-size: 10px !important;
        }
        .RotateButton_canva button{
            font-size: 5px !important;
            height: 26px;
            width: 25px;
        }
}

