.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body{
  background-color: white !important;
}

.main-content {
  overflow-y: auto;
  display: flex;

}

.content {

  width: calc(100% - 250px);
  padding: 5px 0px;
  height: calc(100% - 110px);
  text-align: right;
  position: fixed;
  top: 50px;
  left: 250px;
  overflow-y: scroll;
  transition: left 0.5s ease , 0.4s ease;

}
.content::-webkit-scrollbar, .content2::-webkit-scrollbar{
  background-color: transparent;
  width: 0px !important;
}
.content2{
  width: calc(100% - 50px);
  padding: 5px 0px;
  height: calc(100% - 100px);
  position: fixed;
  left: 50px;
  top: 50px;
  overflow-y: scroll;
  transition: left 0.4s ease;

 }

 .sidebar_toggle2{
  display: block;
 }

/* Media query for 320px width */
@media (max-width: 320px) {
.main-content {

  flex-direction: column-reverse;
  overflow: auto;
}

.content {
  
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  
}
.content,.content2{
  overflow-x: scroll;
  overflow-y: scroll;
 }
}


@media screen and (max-width:600px) {
  .content,.content2{
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 50px;
   }
   .sidebar_toggle2{
    display: none;
   }
  
}
/* Add media query for responsiveness */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column-reverse;
    overflow: auto;
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    /* padding: 20px; */
  }
  .content,.content2{
    overflow-x: scroll;
    overflow-y:scroll;
}
}
