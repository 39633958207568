.Chart_view_container {
  width: 100%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #bc974232;
}
.chart_head {
  height: 25px;

  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: var(--labelcolor);
  background-color: var(--ProjectColor);
}
.chart_body_1 {
  height: auto;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.chart_body_2 {
  gap: 10px;
  height: 400px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.chart_body_1_head {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
}
.chart_body_1_main {
  width: 100%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 20px;
  height: calc(100% - 50px);
}
.chart_body_1_main_1 {
  width: fit-content;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 5px;
  gap: 5px;
  /* background-color: #fff; */
}
.chart_body_1_main_2 {
  padding: 0px;
  width: 300px;
  height: 343px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
}
.chart_body_1_main_3 {
  padding: 10px 0px;
  width:38%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
}
/* .chart_body_1_child{
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: antiquewhite;

} */
.chart_body_1_child_1 {
  height: 100px;
  width: 200px;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
}
/* .for_over_pie{
    padding-top: 15px;
    box-sizing: border-box;
} */
.chart_body_1_child_1_body {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 10px;
}
.chart_body_1_main_1_container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
.chart_body_1_main_1_container1 {
  height: 360px;
  width: 400px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  background-color: #fff;
  border-radius: 15px;
}
.chart_body_1_main_1_container1 .chart_body_1_child_1 {
  box-shadow: 1px 1px 5px 2px rgb(187, 186, 186);
}
.chart_body_1_main_1_container1 .chart_body_1_main_3_body_head {
  margin-bottom: 20px;
}
.chart_body_1_main_1_container1 .chart_body_1_main_3_body_head,
.chart_body_1_main_3_body_head h3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart_body_1_child_1_body_icon {
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  background-color: var(--ProjectColor);
  color: var(--labelcolor) !important;
  border-radius: 5px;
}

.overview_piechart .css-66gjpw-MuiResponsiveChart-container {
  width: 200px !important;
  height: auto !important;
}
.overview_piechart .css-66gjpw-MuiResponsiveChart-container > svg {
  width: 80% !important;
  height: 100% !important;
}
.overview_piechart .recharts-surface {
  width: 65px !important;
  height: 65px !important ;
}
.overview_piechart .recharts-wrapper {
  width: 65px !important;
  height: 65px !important;
}
.chart_body_1_child_1_body_icon .css-i4bv87-MuiSvgIcon-root {
  font-size: 20px !important;
}
.chart_body_1_child_1_body_chart {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.chart_body_1_child_1_body_count h3 {
  pad: 0px !important;
  margin: 0px !important;
  font-size: 17px;
  color: var(--labelcolor);
}
.chart_body_1_child_1_body_name {
  font-size: 15px;
  font-weight: 600;
}
.chart_body_1_child_1_body_chart .chart_body_1_child_1_body_count {
  color: var(--labelcolor);
  width: 70px;
}
.chart_body_1_main_2_body {
  width: 95%;
  height: 85%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart_body_1_main_2_body_body {
  padding: 5px 3px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
}
.chart_body_1_main_2_body_body1 {
  display: flex;
  gap: 20px !important;
}
.chart_child_count_1 {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.chart_child_color {
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
.chart_body_1_main_2_body_pie {
  width: 100%;
  height: 160px;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recharts-pie,
.recharts-layer {
  width: 100%;
  height: 150px !important;
}
/* .css-1sunrpn-MuiResponsiveChart-container {
    height: 150px !important;
    width: 270px !important;
}
.css-1sunrpn-MuiResponsiveChart-container>svg{
    height: 130px !important;
    width: 250px !important;
    display: grid !important;
    place-items: center !important;
} */
/* .css-1j2l0jy-MuiChartsLegend-chartsSeriesLegendGroup,.css-ip0i3g-MuiChartsLegend-mark,.css-1lp2p8j-MuiChartsLegend-label,.css-1vg5rmx-MuiChartsLegend-root{
    display: none !important;
    width: 0px !important;
}
.chart_body_1_main_2_body_pie .css-1h2rxh5-MuiChartsLegend-root{
    display: none !important;
} */
.chart_body_1_main_3_body {
  height: 230px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}
.chart_body_1_main_3_body_head {
  color: var(--labelcolor);
  display: flex;
  align-items: flex-start;
}
.chart_linechart {
  height: 200px !important;
  width: 400px !important;
}
.custom-tooltip {
  position: relative;
  z-index: 3;
  font-size: 15px !important;
  font-weight: 600;
  color: var(--labelcolor);
}

.recharts-legend-item{
margin-right: 0px !important;
}
text tspan {
  font-weight: 600 !important;
  font-size: 12px !important;
}
.new_chart_body_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.barchart_1 {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.barchart_container {
  background-color: #fff;
  width: 70%;
  padding-top: 10px;
  display: grid;
  place-items: center;
  overflow: auto;
  border-radius: 15px;
}
.barchart_2 {
  height: 100%;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
}
.chart_body_1_main_2_body_head h3 {
  color: var(--labelcolor);
  display: flex;
  align-items: center;
  justify-content: center;
}
.barchart_2 .chart_body_1_main_3_body_head h3 {
  height: 30px;
  width: 100%;
  color: var(--labelcolor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.css-7ldjcq-MuiGrid-root {
  margin-left: 10px !important;
  margin-right: 5px !important;
  margin-top: -5px !important;
  width: calc(100% - 30px) !important ;
}
.css-x8g370-MuiInputBase-root-MuiInput-root {
  width: 35px !important;
}
.css-1o639dn-MuiResponsiveChart-container > svg {
  width: 100%;
  height: 100%;
}
.new_box_success {
  display: flex;
font-size: 15px;
  width: 260px;
  margin-left: 5px;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.togglebutton_container {
  height: 40px;
}

.dww3{
  border: 1px solid var(--ProjectColor);
  box-shadow: 1px 1px 5px 5px rgb(212, 229, 229);
}
.recharts-legend-wrapper{
  bottom: -10px !important;
}

@media screen and (max-width: 1200px) {
  .chart_body_1_main {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .Chart_view_container {
    width: 100%;
    /* height: 260%; */
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #e1e6ea;
  }
  .chart_body_1_main_1 {
    height: auto;
  }
  .chart_body_1_main_1_container {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  .chart_body_1 {
    height: auto;
  }
  .chart_body_1_main {
    height: auto;
  }
  .chart_body_1_main_1 {
    /* height: 260px; */
    width: 80%;
    border: 0px;
  }
  .chart_body_1_main_3,
  .chart_body_1_main_2 {
    width: 450px;
  }
  .chart_body_1_main_3_body {
    max-width: 90%;
    margin: 0 auto;
  }
  .chart_linechart {
    height: 200px !important;
    width: 500px !important;
  }

  .chart_body_1_child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .chart_body_1_child_1 {
    width: 220px;
  }
  .barchart_1 {
    width: 90%;
    display: grid;
  }
  .chart_body_2 {
    height: auto;
    gap: 10px;
    flex-direction: column;
  }
  .barchart_2 {
    width: 700px;
  }
  .chart_body_1_main_1_container1 {
    width: 450px;
    height: 300px;
  }
}
@media screen and (max-width: 800px) {
  .barchart_1 {
    width: 90%;
  }
  .barchart_2 {
    width: 450px;
  }
  .chart_body_1_main_1_container1 {
    width: 450px;
    height: 300px;
  }
  .Chart_view_container {
    width: 100%;
    /* height: 270%; */
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #e1e6ea;
  }
  
}
@media screen and (max-width: 500px) {
  .chart_body_1_main_3,
  .chart_body_1_main_2 {
    width: 300px;
  }
  .chart_body_1_main_3_body {
    max-width: 90%;
    margin: 0 auto;
  }
  .chart_linechart {
    height: 180px !important;
    width: 290px !important;
  }
  .barchart_1 {
    width: 95%;
  }
  .barchart_2 {
    width: 100%;
  }
  .chart_body_1_main_1_container1 {
    width: 100%;
    height: 300px;
  }
  .barchart_2 .css-1v5z18m {
    width: 260px !important;
  }
  .new_box_success {
    width: 80px;
    font-size: 13px;
  }
  .togglebutton_container {
    width: 80px !important;
    height: 30px !important;
  }
  /* .css-1o639dn-MuiResponsiveChart-container {
    width: 280px !important;
    overflow: hidden;
  } */
  .Chart_view_container {
    width: 100%;

    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #e1e6ea;
  }
  
  .text tspan {
    font-size: 19px !important;
  }
  
  
}
