.userhole_container {
    width: calc(100% - 230px); 
   width: 100%;
   margin: 0 auto;
   border: none;
   font-family: Arial, sans-serif;
   color: var(--labelcolor);
   font-size: 15px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 10px;
   
  }
  
  .makeStyles-formControl-1 {
    width: 150px !important;
  }
  
  .user_patienthead {
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    padding: 10px 10px 10px 10px;
    margin: 10px 10px;
    border-radius: 5px;
    text-align: start;
    width: 98%;
    display: flex;
    justify-content: flex-start;
    
   }
  
  
   .user_row1 {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     margin-bottom: 20px;
     border-bottom: 1px solid var(--ProjectColor);
     margin-top: 20px;
     width: 100%;
     padding: 3px;
    
  }
  
  
  .user_input2 {
    display: flex;
    width: 350px;
  
   
   }
  
   .user_input2 label {
    font-weight: bold;
    text-align: start;
     margin-bottom: 5px;
     padding-top: 7px;
     width: 100px;
     padding: 5px;
     margin-right: 30px;
  
   
   }
  
  
   .user_input2 input {
    border: none;
    outline: none;
    width: 200px;
    font-size: 13px;
    padding: 5px;
    
   }
  
   .user_input2 select{
    width: 100px;
    border: none;
    outline: none;
   }
  
   .uerregisfooter {
    text-align: center;
    display: flex;
    justify-content: center;
   
   }
   
   .uerregisfooter button {
    padding: 10px;
    width: 100px;
    border-radius: 25px;
    background-color: var(--ProjectColor);
    border: none;
   
   }
   
   .uerregisfooter button:hover {
    background-color: var(--ProjectColorhover);
    color: var(--projectwhite);
    cursor: pointer;
   }
  
   ::placeholder {
    color: rgb(151, 150, 150);
   }
  
  
  
  
   .customMultiSelect {
    position: relative;
  
   
  }
  
  .customMultiSelect .dropdown-container {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 500px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
   
  
  }
  
  .customMultiSelect .dropdown-container .option {
    padding: 8px 12px;
    cursor: pointer;
  
  }
  
  .customMultiSelect .dropdown-container .option:hover {
    background-color: #f0f0f0;
  }
  
  /* Adjust checkbox alignment */
  .customMultiSelect .dropdown-container input[type="checkbox"] {
    margin-right: 8px; /* Add margin to the right of checkboxes for spacing */
  height: 13px;
  width: 13px;
  }
  
  
  .rmsc .dropdown-heading {
  
  text-align: start !important;
  
  }
  .flex_acrss_usr_regis3{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    /* text-align: center !important; */
    row-gap: 10px;
  }
  
  .flex_acrss_usr_regis3_label{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
  }
  .flex_acrss_usr_regis3_label label{
    width: 150px !important;
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
    align-items: center;
    text-align: start;
    font-size: 13px;
    font-weight: bold;
  }

  css



.DivCenter_container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: grey;
  font-size: 16px;
  font-weight: 600;
}

.displayuseraccess{
 display: flex;
 flex-wrap: wrap;
 justify-content: space-around;
 align-items: flex-start;
 row-gap: 15px;
}

.displayuseraccess_child{
  flex: 0 1 calc(250px - 10px);
}
.par_acc_lab{
  color: black;
  font-size: 16px;
  font-weight: 600;
  margin-left: 9px;
}
.chi_acc_lab{
  color:grey;
  margin-left: 9px;
}

  
   @media screen and (max-width: 1193px) {
    
    .user_patienthead {
      background-color: var(--ProjectColor);
      color: var(--labelcolor);
      padding: 10px 10px 10px 10px;
      border-radius: 5px;
      text-align: start;
      width: 95%;
      
     }
  
   }
  
  
   /* Media Query for Responsive Design */
  @media screen and (max-width: 768px) {
    .user_row1 {
      margin: 15px;
      width: 350px;
    }
  
    .user_patienthead {
      background-color: var(--ProjectColor);
      color: var(--labelcolor);
      padding: 10px 10px 10px 10px;
      border-radius: 5px;
      text-align: start;
      width: 93%;
      
     }
     .customMultiSelect .dropdown-container {
      width: 270px;
     }
  
     .flex_acrss_usr_regis3_label label{
      width: 120px !important;
  }
  
  
  }
  
  @media screen and (max-width: 425px){
    /* .user_patienthead {
      background-color: var(--ProjectColor);
      color: black;
      padding: 10px 10px 10px 10px;
      border-radius: 5px;
      text-align: start;
      width: 90%;
      
     } */
  
     /* .userhole_container {
      width: calc(100% - 230px); 
     width: 90%;
     margin: 0 auto;
     border: none;
     font-family: Arial, sans-serif;
     color: var(--ProjectColor);
     font-size: 15px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin-top: 10px;
     
    } */
  
    .userform_container{
      width: 100%;
    }
  
    .user_patienthead {
      background-color: var(--ProjectColor);
      color: var(--labelcolor);
      padding: 10px 10px 10px 10px;
      border-radius: 5px;
      text-align: start;
      width: 90%;
      
     }
  
  
     .user_input2 {
      display: flex;
      width: 300px;
    
     
     }
    
     .user_input2 label {
      font-weight: bold;
      text-align: start;
       margin-bottom: 5px;
       padding-top: 7px;
       width: 100px;
       padding: 5px;
       margin-right: 30px;
    
     
     }
    
    
     .user_input2 input {
      border: none;
      outline: none;
      width: 140px;
      font-size: 13px;
      padding: 5px;
      
     }
  
     .user_row1 {
      margin: 15px;
      width: 90%;
    }
    .customMultiSelect .dropdown-container {
      width: 265px;
  
     }
  
     .flex_acrss_usr_regis3_label label{
      width: 120px !important;
  }
  .flex_acrss_usr_regis3_label{
    flex-direction: column;
   row-gap: 5px;
  }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  