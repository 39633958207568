/* InventoryPurchase.css */

/* Default styles */
.form-container input,.form-container select{
    outline: 0px;
}
.QuickStock_container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}
.QuickStock_container_header {
    background-color: var(--ProjectColor);
    padding: 5px;
    color: var(--labelcolor);
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;

}
.QuickStock_container_header h3,.QuickStock_container_totalValue h3{
    margin: 0px;
    display: flex;
    text-align: center;

    align-items: center;
    gap: 10px;
}

.invent_invent {
    margin: 5px;
    width: 100%;
    display: flex;
    flex-direction: start;

}

.Inventory-2 {
    width: 80%;
    padding: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 28px;
    font-size: 12px;
}

.textareaquick{
    height: 50px;
    padding: 5px;

}

.QuickStock_container_div {
    width: 19%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
}

.btn-cancel,
.btn-add {
    background-color: var(--ProjectColor);
    border-radius: 5px;
    width: 100px;
    border: none;
    height: 30px;
    align-items: center;
}

.button_buton_class {
    align-items: center;
    text-align: center;
    margin: 10px;
}

.addtocart {
    margin: 10px;
}

.btn-cancel:hover {
    background-color: var(--ProjectColorhover);
    color: var(--projectwhite);
}

.btn-add:hover {
    background-color: var(--ProjectColorhover);
    color: var(--projectwhite);
}

.QuickStock_container_form { 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    row-gap: 30px;
    /* align-items: center;
    justify-content: center; */
}

.pur_form_head {
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    padding: 10px;
    border-radius: 5px 5px 0 0;
    margin: -20px -20px 20px -20px;
    text-align: center;
}

.pur_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.First_row_vendors,
.purchase_row_number,
.amendment-icons,
.row-buttons {
    /* flex: 1; */
    margin-right: 10px;
}



.label_classname {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 15px;
    color: var(--labelcolor);
}

.Inventory-1 {
    width: 90%;
    padding: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 30px;
    font-size: 12px;
}





.search-input {
    position: relative;
}

.search-input input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-right: 30px;
}

.searching_input_icon {
    /* position: absolute; */
    margin-left: 230px;
    border: none;
    color:var(--labelcolor);
    display: flex;
    top: 5px;
    transform: translateY(-10%);
    margin-top: 26px;
    outline: none;
    top: 5px;
    background: white;
    margin-top: -28px;
    cursor: pointer;

}

.empty_label {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}





.cart-items {
    margin-top: 20px;
    width: 100%;
}



.cart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.QuickStock_container_totalValue{
    width: 100%;
    height: 40px;
    color: var(--labelcolor);
    display: flex;
    align-items:center;
    justify-content: flex-start;
    gap: 10px;
    border-bottom: 1px solid var(--ProjectColor);
}
.QuickStock_container_totalValue input{
    width: 150px;
    border: 0px;
    outline: 0px;
    color: rgb(97, 97, 97);

}
.QuickStock_container_totalValue_1 h3{
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--labelcolor);
}
.Add_btn_Quick{
    width: 100%;
    display: grid;
    place-items: center;
}
.Add_btn_Quick button{
    width: 100px;
    background-color: var(--ProjectColor);
    padding: 8px;
    border-radius: 15px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
}

.Add_btn_Quick button:hover{
    background-color: var(--ProjectColorhover);
    color: var(--projectwhite);
}
.QuickStock_table_container{
    width: 98%;
    overflow: auto;
}
.QuickStock_table{
    width: 1350px;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .QuickStock_table table {
      width: 100%;
      border-collapse:separate;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 2px #c4b9b9;
      
    }
    .QuickStock_table table thead{
      color: var(--ProjectColor);
      font-weight: 600;
      height: 40x;
      width: 100%;
    }
    .QuickStock_table th, td {
        font-size: 12px;
      padding: 8px;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }
   
     .QuickStock_table tr:nth-child(even) {
      background-color: rgba(224, 221, 223, 0.384); 
    }
  
    .QuickStock_table th {
      background-color:  var(--ProjectColor);
      color: var(--labelcolor);
      border-radius: 5px;
    }
    
    .QuickStock_table tr:hover {
      background-color: var(--ProjectColorhover);
      color: var(--projectwhite);
    }
    .QuickStock_Table_count{
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        box-sizing: border-box;
        gap: 20px;
    }
    .QuickStock_Table_count_con{
        width: 300px;
    }
    .QuickStock_Table_count_row{
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .QuickStock_Table_count_row label{
        width: 150px;
        text-align: center;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        color: var(--labelcolor);

    }
    #QuickStock_Table_count_row_tax{
        width: 50px;
        height: 20px;
        border: 1px solid grey;
        border-radius: 5px ;
        outline: 0px;
    }
   .QuickStock_Table_count_row input{
    width: 115px;
    height: 20px;
    border: 1px solid grey;
    border-radius: 5px ;
    outline: 0px;
   }
   .cell_btn{
    color: var(--labelcolor) !important;
   }

 
/* Media queries for responsiveness */

@media (max-width: 912px) {
    .QuickStock_container_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .QuickStock_container{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }


    .form-container {
        align-items: center;
        justify-content: center;
    }

    

    .QuickStock_container_header {
        background-color: var(--ProjectColor);
        padding: 5px;
        color: var(--labelcolor);
        border-radius: 5px;
        margin: 5px;
        width: 100%;
        box-sizing: border-box;
        height: 40px;

    }

    .label_classname {
        display: flex;
        margin: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 13px;
        color: var(--labelcolor);
    }

    .btn-cancel.btn-add:hover {
        background-color: var(--ProjectColorhover);
        color: var(--projectwhite);
    }

    .First_row_vendors,
    .purchase_row_number,
    .amendment-icons,
    .row-buttons {
        flex: 100%;
        margin-right: 0;
    }

    .purchse_btn-purchase {
        margin-right: 0;
    }
    .QuickStock_Table_count{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .QuickStock_container_form {
        row-gap: 10px;
    }


   

}

@media (max-width: 1140px) {

}