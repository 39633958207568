/* Sidebar.css */

#inventory_sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  background-color: var(--opacityColor);
  color: #fff;
  padding-top: 10px;
  z-index: 100;
  text-align: left;
  width: 50px;
  /* Collapsed width */
  overflow-y: auto;
  transition: width 0.4s;
  /* Smooth transition for width */
}

/* Customize the scrollbar track */
#inventory_sidebar::-webkit-scrollbar {
  display: none;
}

#inventory_sidebar.inventory_sidebar_open {
  width: 250px;
  /* Expanded width */
}

.icon_only {
  padding-left: 5px !important;
  justify-content: flex-start !important;
}

.inventory_sidebar_icon {
  color:white;
  width: 30px !important;
}
#inventory_sidebar.inventory_sidebar_open {
  list-style: none;
  padding: 0;
  margin: 0;
}

.icon_tooltip {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px 5px;
  border-bottom: 1px solid rgba(26, 23, 23, 0.1);
  position: relative;
  /* Added this line */
  color: #2f2c2c;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s, transform 0.4s ease;
}

.icon-name1 {
  font-size: 15px;
  display: flex;
  flex-wrap: nowrap !important;
  /* color: white; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.icon-name {
  font-size: 15.3px;
  display: flex;
  flex-wrap: nowrap !important;
  /* color: white; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.icon_tooltip:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#font_111 {
  width: 100%;
  padding: 0px 0px;
  font-weight: 600;
  box-sizing: border-box;
}

.Sidebarmenuhover {
  width: 100%;
  padding: 0px 0px;
  font-weight: 600;
  box-sizing: border-box;
}
.subSidebarmenuhover {
  height: auto;
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 100;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 5px 0px;
  box-sizing: border-box;

  /* display: none; */
}
.subSidebarmenuhover div {
  height: 45px;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000000;
  border-bottom: 1px solid rgba(26, 23, 23, 0.1);
}
.subSidebarmenuhover div span {
  padding: 5px 0px;
  margin-left: 5px;
  box-sizing: border-box;
  font-size: 14.5px;
  /* font-weight: 600; */
  width: 90%;
}
.subSidebarmenuhover div span:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
  font-size: 15.3px;
  color: white;
}

.arrow-icon {
  margin-left: auto; 
  margin-right: 5px;
  color: white;
  transition: transform 0.3s ease; 
}

.table_btns_action{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.table_btns_action button{
  border: 0px;
  background-color: 0px;
}


.arrow-rotate {
  transform: rotate(
    180deg
  ); /* Rotate the arrow 180 degrees when the submenu is open */
  color: white;
}

/* faChartBar */



