
.head_img_head {
  width: 100%;
  height: 280px !important;

}

.head_img {
  position: relative;
  width: 100%;
  height: 213px !important;
  background-color: rgb(192, 190, 190);  
  margin-top: 5px;  
} 
                        
.head_img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.back_inp {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 250%);
}
.prof_round_head {
  display: flex;
  align-items:flex-end;
  position: relative;
  bottom: 75px;

  background-color: transparent;
}
.profile_round {
  width: 150px;
  height: 150px;
  background-color: rgb(240, 236, 236);
  border-radius: 50%;
  position: relative;
}
.profile_round img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
}
.prof_inp {
  position: relative;
  bottom: 85px;
  left: 30px;
  background-color: transparent;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select-title123 {
  width: 50%;
  outline: none;
  border: none;


}


.user_name {
  height: 75px;
  width: 75%;
  padding: 0px 15px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

 .name {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} 
.rolenam h4{
  height: 6px;
  margin-top: 5px;
}
.forms {
  height: 55px;
  width: 780px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid  var(--ProjectColor);                                     
  display: flex;
}
.user_items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  margin-top: 10px;
  /* margin: 0px 136px 0px 163px */
}
.inp,
.put {
  border: transparent;
  /* margin-right: 50px; */
  margin-right: -25px;
  outline: none;
  flex: 1;
}
.first {
  color:  var(--labelcolor);
  justify-content: flex-start;
  display: flex;
  width: 100px;
}


.pack {
  width: 50%;
  margin-right: 25px;
  display: flex;
  justify-content: flex-start;
}

.packlog{
  width: 110%;
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;
}

.custom-button-back {
  position: absolute;
  /* background-color: aliceblue; */
  font-size: 10px;
  color:  var(--labelcolor);
  width: 60px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  z-index: 1; 
}

.custom-button-profile {
  position: absolute;
  /* background-color: aliceblue; */
  font-size: 10px;
  color:  var(--labelcolor);
  width: 60px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: start;
  z-index: 1; 
}

/* .custom-button-profile {

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color:  var(--ProjectColor);
}

.profile_round:hover .custom-button-profile {

  opacity: 1;
  color:  var(--ProjectColor);
}


.custom-button-back {

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color:  var(--ProjectColor);
}

.head_img:hover .custom-button-back {
  color:  var(--ProjectColor);
  opacity: 1;
} */




.file-input {
  display: none; 
}

.head_img {
  position: relative;
}
.save1{
  display: none;
}
.save123{
  margin-right: 13%;
  padding: 8px 20px;
  border-radius: 30px;
  background-color:  var(--ProjectColor);
  border: 1px solid  var(--ProjectColor);
}
.save123:hover{
  background-color:  var(--ProjectColorhover);
  color: var(--projectwhite);
  cursor: pointer;
}
@media (max-width: 1040px) {
  
  .forms {
    flex-direction: column;
    height: auto;
    border: none;
    width:300px;
  }
 
  .user_items{
    margin-top: 10px;
    /* margin-bottom: 100px; */
    display: flex; 
    flex-direction: column;
    padding: 5px;
    border-bottom: 0px !important;
  }


  .pack{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom:1px solid  var(--ProjectColor);                            

  }
  .packlog{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: -20px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom:1px solid  var(--ProjectColor);  
  }
  .first{
    width: 100px;
  }
  .inp,.put{
    margin-right: 0px;

  }
  .save1{
    display: block;
    margin-top: 10px;
    margin-left: -10px;
  padding: 8px 25px;
  border-radius: 30px;
  background-color:  var(--ProjectColor);
  border: 1px solid  var(--ProjectColor);
  }

  .save1:hover{
    background-color:  var(--ProjectColorhover);
    color: var(--projectwhite);
    cursor: pointer;
  }
  .save123{
    display: none;
  }

  .custom-button-profile {
    position: absolute;
    font-size: 10px;
    width: 60px;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:  var(--labelcolor);
    padding: 5px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: start;
    z-index: 1; 
  }
 
}
@media (max-width:450px){

  .prof_round_head {
    display: flex;
    align-items: flex-end;
    position: relative;
    bottom: 45px;
    background-color: transparent;
  }
  .profile_round {
    width: 130px;
    height: 110px;
    background-color: rgb(240, 236, 236);
    border-radius: 60px;
  }

  .rolenam h4{
    height: 5px;
    margin-top: 10px;
    font-size: 20px;
  }
  .rolenam h6{
    font-size: 13px;
  }
  .save1{
    display: block;
    margin-top: 10px;
  padding: 8px 20px;
  border-radius: 30px;
  background-color:  var(--ProjectColor);
  border: 1px solid  var(--ProjectColor);
  }
  .save123{
    display: none;
  }
  .forms{
    width: 250px;
  }

  .custom-button-profile {
     position: absolute;
    /* background-color: aliceblue; */
    width: 60px;
    font-size: 10px;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:  var(--labelcolor);
    padding: 5px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: start;
    z-index: 1;
  }

  .custom-button-back {
    position: absolute;
    /* background-color: aliceblue; */
    font-size: 10px;
    width: 60px;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:  var(--labelcolor);
    padding: 5px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    z-index: 1;
  }
}

@media (max-width:320px){


  .profile_round {
    width: 140px;
    height: 110px;
    background-color: rgb(240, 236, 236);
    border-radius: 60px;
  }
}
